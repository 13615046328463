<template>
	<div class="orders">
		<h1 class="mb-4">Коммерческие предложения</h1>

		<v-row class="mb-4">
			<v-col>
				<v-btn color="primary" @click="$router.push({name:'order-new'})">
					<v-icon class="mr-2">mdi-plus</v-icon>
					Создать предложение
				</v-btn>
			</v-col>
		</v-row>

		<v-data-table :headers="headers"
					  :items="orders"
					  :items-per-page="-1"
					  disable-sort
					  hide-default-footer
					  @click:row="onEditOrder">

			<template v-slot:item.number="{ _, index }" class="d-flex justify-end">
				{{ index + 1 }}
			</template>

			<template v-slot:item.manager="{ item }">
				{{ manager(item) }}
			</template>

			<template v-slot:item.productCount="{ item }">
				{{ productCount(item) }}
			</template>

			<template v-slot:item.totalPrice="{ item }">
				<div v-if="item.DISCOUNT" class="caption text-no-wrap grey--text text-decoration-line-through">
					{{ orderPrice(item, true) | nf }} ₽
				</div>
				<div class="text-no-wrap">
					{{ orderPrice(item) | nf }} ₽
				</div>
			</template>

			<template v-slot:item.actions="{ item }">
				<div class="d-flex justify-end flex-nowrap">
					<v-btn @click.stop="onEditOrder(item)" icon title="Редактировать">
						<v-icon>mdi-pencil</v-icon>
					</v-btn>
					<v-btn @click.stop="onExportOrder(item)" icon
						   title="Экспорт в Google Таблицы">
						<v-icon>mdi-google-spreadsheet</v-icon>
					</v-btn>
					<v-btn @click.stop="onDeleteOrder(item)"
						   :loading="$store.state.dialogDelete.loading"
						   icon
						   title="Удалить">
						<v-icon>mdi-delete-outline</v-icon>
					</v-btn>
				</div>
			</template>

			<!--			<template v-slot:item.status="{ item }" class="d-flex justify-end">
							<v-chip color="grey" text-color="white">Черновик</v-chip>
						</template>-->

		</v-data-table>

		<v-dialog v-model="withExportDialog" width="400">
			<v-card v-if="loadingExport">
				<v-card-title class="justify-center">Экспортируем в Google Таблицы</v-card-title>
				<v-card-text class="d-flex flex-column align-center">
					<p>Пожалуйста, подождите...</p>
					<v-progress-circular indeterminate/>
				</v-card-text>
			</v-card>
			<v-card v-else>
				<v-card-title class="justify-center">Готово!</v-card-title>
				<v-card-text>
					<p>Документ создан на Вашем Google Диске:</p>
					<a :href="exportUrl" target="_blank">{{ exportUrl }}</a>
				</v-card-text>
				<v-card-actions class="justify-center">
					<a :href="exportUrl" target="_blank">
						<v-btn @click="withExportDialog = false" color="primary">Открыть</v-btn>
					</a>

				</v-card-actions>
			</v-card>
		</v-dialog>
	</div>
</template>

<script>

import {totalPrice} from "@/utils/product";
import Config from "@/config";

export default {
	name: "orders",
	data() {
		return {
			headers: [
				{text: '#', value: 'number'},
				{text: 'Дата/время', value: 'DATE_CREATE'},
				{text: 'Название', value: 'NAME', width: "30%"},
				{text: 'Менеджер', value: 'manager'},
				{text: 'Кол-во позиций', value: 'productCount', align: 'center'},
				{text: 'Цена', value: 'totalPrice', align: 'right'},
				//{text: 'Статус', value: 'status', align: 'center'},
				{text: '', value: 'actions', align: 'right', sortable: false},
			],

			withExportDialog: false,
			loadingExport: false,
			exportUrl: null,
		}
	},
	computed: {
		orders() {
			return this.$store.state.page?.OrderListController?.items || [];
		},
		user() {
			return this.$store.state.session?.user;
		},
		products() {
			return this.$store.state.page?.OrderListController?.products;
		},
		modules() {
			return this.$store.state.page?.OrderListController?.modules;
		},
	},
	methods: {
		manager(item) {
			// todo
			return this.$store.state.session.user.NAME + " " + this.$store.state.session.user.LAST_NAME;
		},
		productCount(item) {
			if (item.PRODUCT_IDS) return item.PRODUCT_IDS.length;
			else return '0';
		},
		orderPrice(item, skipDiscount = false) {
			const productPrice = (product) => {
				if (product.MODULE_IDS?.length) {
					return totalPrice(product.MODULE_IDS?.map(id => this.modules.find(module => id === module.ID)), skipDiscount ? 0 : item.DISCOUNT);
				}
				return 0;
			};
			const productTotalPrice = (product) => {
				const index = item.PRODUCT_IDS.indexOf(product.ID);
				console.log("PRODUCT ", product.NAME + ": " + index, item.PRODUCT_COUNTS);
				let amount = item.PRODUCT_COUNTS ? Number(item.PRODUCT_COUNTS[index]) : 1;
				if (isNaN(amount)) amount = 1;
				console.log("\tamount", amount);
				return productPrice(product) * amount;
			};

			const products = item.PRODUCT_IDS?.map(id => this.products.find(el => el.ID === id)).filter(el => !!el);
			return products?.reduce((acc, product) => acc + productTotalPrice(product), 0);
		},
		/*findManager(item) {
		  // console.log(this.user)
		},*/
		onEditOrder(item) {
			this.$router.push({name: 'order', params: {id: item.ID}})
		},
		onDeleteOrder(item) {
			this.$store.state.dialogDelete.visible = true;
			this.$store.state.dialogDelete.callback = () => {
				this.$store.state.dialogDelete.loading = true;

				this.$store.dispatch('del', {
					action: 'OrderItemController',
					params: {
						id: item.ID
					}
				})
					.then(() => {
						//this.$router.push({name: 'orders'});
						const index = this.orders.findIndex(el => el.ID === item.ID);
						this.orders.splice(index, 1);
					})
					.catch((error) => {
						console.log("ERROR: ", error);
					}).finally(() => {
					this.$store.state.dialogDelete.loading = false;
					this.$store.state.dialogDelete.visible = false;
				});
			}

		},
		onExportOrder(item) {
			this.withExportDialog = true;
			this.loadingExport = true;

			this.$store.dispatch('post', {
				action: 'GoogleSheetController',
				params: {
					id: item.ID
				}
			}).then((res) => {
				this.exportUrl = res.page.GoogleSheetController.url;
			}).catch((error) => {
				console.warn("ERROR: ", error);
				if (error.code === Config.ERROR_CODE_EXPIRED) {
					this.$store.state.dialogError.visible = true;
					this.$store.state.dialogError.message = error.error;
					this.$store.state.dialogError.callback = () => {
					}
				}
			}).finally(() => {
				this.loadingExport = false;
			});
		},
	},
}
</script>

<style lang="scss">
.orders {
	&__cards {
		display: grid;
		gap: 8px;
	}

	tbody {
		tr {
			cursor: pointer;
		}
	}

	&__card {
		display: flex;
		justify-content: space-between;
		transition: .2s ease;
		width: 100%;
		height: 200px;
		padding: 24px;
		background: $gray;
		cursor: pointer;

		&:hover {
			background: rgba(211, 211, 211, .8);
		}
	}
}
</style>
